<template>
    <section>
        <div v-if="vistaCrear" class="row mx-0 align-items-center border-left py-2 bg-white br-tr-8" style="height:65px;">
            <div v-show="!creando && $can('gestionar_geocercas')" class="btn-general border mx-2 px-1" @click="addGeocerca">
                <i class="icon-geocerca f-18 text-general text-white" />
                Crear Geocerca
            </div>
            <div v-show="creando" class="btn-grey ml-auto px-2" @click="drawGeocercas">
                Cancelar
            </div>
            <div v-show="creando" class="btn-general px-2 ml-2" @click="getGeocerca">
                Terminado
            </div>
        </div>
        <mapa-google
        ref="GoogleMaps"
        :coordenadas="coordenadas"
        :coordenadas-entrada="coordenadas"
        height="calc(100vh - 215px)"
        width="100%"
        />
        <!-- Partials -->
        <modal ref="modalCrearGeocerca" titulo="Crear geocerca" no-aceptar adicional="Guardar" @adicional="guardarGeocerca">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <small class="pl-3 text-general">
                        Nombre la geocerca
                    </small>
                    <el-input v-model="form.nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                </div>
                <div class="col-10 mt-3">
                    <small class="pl-3 text-general">
                        Descripción
                    </small>
                    <el-input v-model="form.descripcion" placeholder="Descripción" maxlength="300" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Geocercas from '~/services/geocerca/geocercasPuntos'
export default {
    data(){
        return {
            // componentName: 'listarGrupos'
            coordenadas: {
                lat: 7.080734,
                lng: -73.147827,
            },
            creando:false,
            form:{
                nombre:'',
                descripcion:'',
                idGrupo:null,
                puntos:[]
            }
        }
    },
    computed:{
        ...mapGetters({
            geocercas:'geocercas/geocercas/geocercas',
            geocercasGrupo:'geocercas/geocercas/geocercasGrupo'
        }),
        vistaCrear(){
            const ruta = this.$route.name
            if (ruta == "admin.geocercas.grupos"){
                return false
            } else if ( ruta == "admin.geocercas.grupos.ver"){
                return true
            }
            return false
        },
        idGrupo(){
            return Number(this.$route.params.idGrupo)
        }
    },
    mounted(){
        this.initMapa()
        //this.drawGeocercas()
    },
    methods:{
        initMapa(){
            //console.log('acaaaaaaaaafdsgfgd');
            this.creando = false
            this.$refs.GoogleMaps.calcularPosicionInicial()
            this.$refs.GoogleMaps.inicializarGoogleMaps()
        },
        async drawGeocercas(){
            setTimeout(() => {
                this.$refs.GoogleMaps.drawGeocercas(this.geocercas)
            }, 500);
        },
        async drawGeocercasGrupo(){
            setTimeout(() => {
                this.$refs.GoogleMaps.drawGeocercas(this.geocercasGrupo)
            }, 500);
        },
        addGeocerca(){
            this.creando = true
            this.$message('!Por favor dibuje la Geocerca en el mapa!');
            this.$refs.GoogleMaps.createGeocerca()
        },
        getGeocerca(){
            const vertices = this.$refs.GoogleMaps.getCordenadasPoligono()
            if(vertices.length == 0){
                this.$message('!Por favor dibuje la Geocerca en el mapa!');
                return
            }
            this.form.puntos = vertices
            this.$refs.modalCrearGeocerca.toggle()
        },
        async guardarGeocerca(){
            try {

                this.form.idGrupo = this.idGrupo
                const {data} = await Geocercas.guardarGeocerca(this.form)
                this.notificacion('Exito','Geocerca almacenada','success')
                this.$store.commit('geocercas/geocercas/addGeocerca', data.geocerca)
                this.$refs.modalCrearGeocerca.toggle()
                this.initMapa()
                this.drawGeocercas()

            } catch (error){
                this.error_catch(error)
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.column-group{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
</style>
